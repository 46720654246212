/* global document */
import EmailForm from "./EmailForm";
import { EnvContext } from "./context/env-context";
import { LangContext } from "./context/lang-context";
import { RegionContext } from "./context/region-context";
import "./i18n";
import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom/client";

const lang = document.documentElement.lang.split("-")[0];

const region = Cookies.get("koenig_region") ?? "at"; // @todo fallback region!

const container = document.getElementById("koe_contact");
const env = container.dataset.env;
const fields = JSON.parse(container.dataset.fields);

const root = ReactDOM.createRoot(container);

root.render(
  <EnvContext.Provider value={env}>
    <LangContext.Provider value={lang}>
      <RegionContext.Provider value={region}>
        <EmailForm fields={fields} />
      </RegionContext.Provider>
    </LangContext.Provider>
  </EnvContext.Provider>
);
