/* global URLSearchParams, window, fetch, console, FormData */
import Input from "./components/Input";
import ModalDrawer from "./components/ModalDrawer";
import Button from "./components/wilhelm/Button";
import Format from "./components/wilhelm/Format";
import { LangContext } from "./context/lang-context";
import { ReactComponent as CheckIcon } from "./icons/check.svg";
import { ReactComponent as EmailIcon } from "./icons/email.svg";
import eventBus from "./utilities/event-bus";
import clsx from "clsx";
// import { RegionContext } from "./context/region-context";
// import { useContactFormStore } from "./store/contact-form-store.ts";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function EmailForm(props) {
  const { t } = useTranslation();

  const lang = useContext(LangContext);
  // const region = useContext(RegionContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [response, setResponse] = useState({
    success: false,
    messages: [],
  });

  useEffect(() => {
    eventBus.on("contact_open", (data) => {
      setModalOpen(true);
      console.log(data);
      setFormData(data);
    });
  }, []);

  const formRef = useRef();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const watchTerms = watch("frm_terms", false);

  const onSubmit = (data) => {
    const params = {
      ...data,
      frm_internal: JSON.stringify(formData.search),
      frm_contact: formData.contact,
    };
    const url = window.location;

    fetch(url, {
      method: "POST",
      // mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(params),
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((res) => {
        if (res.success) setResponse(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hiddenFields = props?.fields.filter((field) => !!field.value) ?? [];
  const spamFields = props?.fields.filter((field) => !field.value) ?? [];

  return (
    <>
      <ModalDrawer
        buttons={
          <>
            {response.success !== true && (
              <div className="tw-w-full tw-py-3 tw-px-8">
                <Button
                  className="tw-w-full"
                  disabled={!watchTerms}
                  type="submit"
                  title={t("contact_form.submit")}
                />
              </div>
            )}
          </>
        }
        outerElement="form"
        outerProps={{
          ref: formRef,
          onSubmit: handleSubmit(onSubmit),
        }}
        isOpen={modalOpen}
        scrollable={true}
        closeFn={() => {
          setModalOpen(false);
        }}
        innerClassName="tw-p-8 tw-pt-12"
      >
        <div className="tw-text-center">
          <Format preset="h5" className="tw-text-red-main">
            {t("contact_form.contact_sales_title")}
          </Format>
          <Format preset="h2" className="tw-mt-5">
            {t("contact_form.contact_sales_subtitle")}
          </Format>
          <Format preset="body-small" className="tw-mt-4 tw-text-grey-soft">
            {t("contact_form.contact_sales_text")}
          </Format>
        </div>

        {response.success === true && (
          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-text-center tw-backdrop-blur-sm tw-bg-grey-darkest/25">
            <div className="tw-inline-flex tw-flex-col tw-items-center tw-gap-8 tw-text-grey-soft">
              <div className="tw-inline-flex tw-flex-col tw-items-center tw-gap-2">
                <EmailIcon className="tw-w-8 tw-h-8 tw-fill-current" />
                <ul className="">
                  {response.messages.map((message, index) => (
                    <Format preset="h4" as="li" key={`message_${index}`}>
                      {message.message}
                    </Format>
                  ))}
                </ul>
              </div>
              <Button
                onClick={() => {
                  setModalOpen(false);
                }}
                className="w-button--grey-main"
                options={{
                  style: "primary",
                  size: "medium",
                }}
                type="button"
                title={t("button.close")}
                icon={<CheckIcon className="tw-w-4 tw-h-4 tw-fill-current" />}
                iconPosition="after"
              />
            </div>
          </div>
        )}

        {response.success !== true && (
          <div className="tw-block tw-mt-10">
            {hiddenFields.map((field) => {
              return !!field.value ? (
                <input {...register(field.name)} key={field.name} type="hidden" {...field} />
              ) : null;
            })}

            <Input
              label={t("contact_form.email")}
              type="email"
              {...register("frm_email", { required: t("contact_form.required") })}
              error={errors.frm_email}
            />
            <Input
              label={t("contact_form.name")}
              {...register("frm_name", { required: t("contact_form.required") })}
              error={errors.frm_name}
              className="tw-mt-5"
            />
            <Input
              label={t("contact_form.company")}
              {...register("frm_company", { required: t("contact_form.required") })}
              error={errors.frm_company}
              className="tw-mt-5"
            />
            <Input
              label={t("contact_form.phone")}
              {...register("frm_phone", { required: t("contact_form.required") })}
              error={errors.frm_phone}
              className="tw-mt-5"
            />
            <Input
              label={t("contact_form.message")}
              element="textarea"
              {...register("frm_message", { required: t("contact_form.required") })}
              error={errors.frm_message}
              className="tw-mt-5"
            />
            {formData.mode === "product" && (
              <Input
                label={t("contact_form.additional_label")}
                placeholder={t("contact_form.additional_placeholder")}
                element="textarea"
                {...register("frm_additional")}
                error={errors.frm_additional}
                className="tw-mt-5"
              />
            )}

            <div className="tw-flex tw-items-center tw-mt-10">
              <Format
                preset="caption"
                htmlFor="default-checkbox"
                as="label"
                className="tw-flex tw-items-center"
              >
                <input
                  id="default-checkbox"
                  type="checkbox"
                  {...register("frm_terms", { required: true })}
                  value="1"
                  className={clsx(
                    "tw-form-checkbox tw-w-5 tw-h-5 tw-text-grey-darkest tw-bg-grey-darkest tw-rounded-sm tw-border tw-border-grey-medium",
                    "focus:hover:tw-border-grey-medium focus:hover:tw-bg-grey-dark hover:tw-bg-grey-dark",
                    "checked:tw-bg-grey-darkest checked:focus:tw-border-grey-medium checked:tw-border-grey-medium",
                    "focus:tw-bg-grey-darkest focus:tw-border-red-main focus:tw-ring-0 focus:tw-ring-offset-0 focus:tw-outline-0"
                  )}
                />
                <span className="tw-ml-2">{t("contact_form.terms")}</span>
              </Format>
            </div>

            {spamFields &&
              spamFields.map((field) => {
                return (
                  <Input
                    {...field}
                    {...register(field.name, {
                      required: true,
                      validate: (value) => value.toString() === field.solution.toString(),
                    })}
                    error={errors[field.name]}
                    className="tw-mt-10"
                    key={field.name}
                  />
                );
              })}
          </div>
        )}
      </ModalDrawer>
    </>
  );
}

export default EmailForm;
